.footer {
  background: #00000f;
  padding: 2em;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    
}

.footer-links {
  width: 100%;
  margin: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.footer p {
  color: #fff;
  font-size: 1em;
}
.footer h3 {
  margin-bottom: 0.5em;
}

@media screen and (max-width: 528px) {
  .footer-links {
    width: 100%;
    margin: 0.5em;
    /* flex-direction: column; */
    align-items: center;
    /* justify-content: space-around; */
    text-align: center;
  }

  .type{
    margin-bottom: 1em;
  }

  .footer h3 {
    margin-bottom: 0.3em;
  }

  .rights{
    font-size: 0.7em;
  }
}

