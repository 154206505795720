.portfolio-cards{
  padding:  4em 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  background: #f7fcff;
}

.cards-content{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 400px;
  height: 350px;
  background: #fff;
  box-shadow: 1px 3px 4px 4px rgba(194,194,194,0.78);
}

.cards-content img{
  width: 100%;
  height: 100%;
}
