.services {
  width: 100%;
  /* z-index: 1; */
  
  
}




.services-container{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  
}
.services-container .service-image{
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width:100%;
  height: 60vh;
  position: absolute;
  overflow: hidden;
  
}


.services-container .service-image img{
  position: absolute;
  width:100%;
  height: 100%;
  object-fit: cover;
}

.services-container .service-image .service-overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  background: #00000f;
  opacity: 0.5;
}

.services-container .text{
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
}

.services-container .text h1{
  position: relative;
  margin: 0;
  padding: 0;
  display: block;
  transform-origin: left;
  text-transform: uppercase;
  transform: scaleX(1);
  font-size: 3em;
  margin-bottom: 0.2em;
  color: #fff;
  animation: revealTextReverse 1s ease-in-out forwards;
 
}

.services-container .text h1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  transform-origin: right;
  animation: revealText 1s ease-in-out forwards;
  animation-delay: 0.5s;
}

@keyframes revealTextReverse {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes revealText {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

.services-container .text .block {
  background: #fff;
  margin-top: 2em;
  width: 30%;
  height: 8px;

}




/* service cards */


.service-card{
  padding:  4em 2em;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  background: #f7fcff;
}


.service-card-content{
  display: flex;
  /* justify-content: space-around; */
  flex-direction: column;
  width: 350px;
  height: 350px;
  background: #fff;
  margin: 1em;
  box-shadow: 1px 3px 4px 4px rgba(194,194,194,0.78);

}

.service-card-content .image{
  height: 55%;
}

.service-card-content img{
  width: 100%;
  max-height: 100%;
  

}

.service-card-content img:hover {
  cursor: pointer;
  transform: scale(1.01);
  transition: 0.6s ease-in-out;
}

.service-card-content .text{
  padding: 1em;
  
}

.service-card-content .text h3{
  font-size: 1.5em;
  color: #333;
  margin-bottom: 0.3em;
  /* padding: 1em; */
}

.service-card-content .text p{
  color: #333;
 
}

.service-card-content .text button {
  color: #333;
  font-family: "Roboto Condensed";
  background: transparent;
  padding: 0.6em;
  border: 2px solid #333;
  /* border-radius: 2px; */
  font-weight: bold;
  font-size: 1em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 1em;
}

.service-card-content .text button:hover{
  background: #193498;
  color: #fff;
  border: none
}

/* .service-card-content .text button:hover {
  transform: scale(1.02);
  transition: 0.5s ease-out;
} */


.link {
  text-decoration: none;
  margin: 0;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: animate 1s linear infinite;
}

@keyframes animate {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

.icon:hover{
  transform: translateX(2px) ;
}

@media screen and (max-width:768px){

  .services-container{
    padding: 2em;
  }
  
  .services-container .text h1{
  font-size: 2.5em;
}

.service-card{
  padding: 2em;

}



}

