.img-slider {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em;
    overflow: hidden;
    max-height: 120vh;
  
  }

 .img-slider .text{
     width: 100%;
     margin-bottom: 4em;
 }

  .img-slider h2 {
    font-size: 2.5em;
    color: #193498;
    margin-bottom: 0.4em;
    text-transform: uppercase;
    text-align: left;
  }
  
  .img-slider .block {
    background: #193498;
    margin-top: 2em;
    width: 7%;
    height: 8px;
  
  }

  .slider{
    position: relative;
    /* margin: 2em; */
    height: 65vh;
    width: 90%;
  }
  
  .slider img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .right-arrow {
    position: absolute;
    top: 45%;
    right: 1px;
    font-size: 3em;
    color: blue;
    z-index: 5;
    cursor: pointer;
    user-select: none;
  }
  
  .left-arrow {
    position: absolute;
    top: 45%;
    left: 1px;
    font-size: 3em;
    color: blue;
    z-index: 5;
    cursor: pointer;
    user-select: none;
  }
  
  .slide {
    opacity: 0;
    transition-duration: 1s ease;
    
  }
  
  .slide.active {
    opacity: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
    transition-duration: 1s;
    transform: scale(1.08);
  }

  .more{
    margin-top: 1em;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 2em;
    color: #00000f;
    
  }

  .more:hover {
    cursor: pointer;
    color: #193498
  }

  .more h3{
    margin-right: 0.2em;
    font-size: 1.1em;
  }

  .link{
    text-decoration: none;
    width: 100%;
  }

  @media screen and (max-width: 968px ){
    .img-slider h2 {
        font-size: 2em;
        margin-bottom: 0.2em;
      
      }

      .img-slider .block {
        margin-top: 1em;
        width: 15%;
      }
  }

  @media screen and (max-width: 569px ){
    .img-slider h2 {
      font-size: 1.8em;
      margin-bottom: 0.3em;
    }

    .slider{
      position: relative;
      height: 40vh;
      width: 90%;
    }

  }
  