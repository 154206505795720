.project-overview {
  padding: 5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: #00000f; */
}

.project-overview .header {
  margin: 3em 0;
  width:100%;
  /* text-align: right; */
  
}

.project-overview .header h2 {
  font-size: 2.5em;
  color: #193498;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.project-overview .header .block {
  background: #193498;
  margin-top: 1em;
  display:inline-block;
  width: 10%;
  height: 8px;
  text-align: right;
}

.section-overview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2em 0;
}

.section-overview .project-image {
  flex: 1;
  width: 50%;
  height: 350px;
  margin-right: 2em;
  border: 3px solid #cfecfd;
  border-radius: 5px;
}
.section-overview .project-image img {
  width: 100%;
  height: 100%;
}

.section-overview .section-text {
  flex: 1;
  margin-right: 2em;
}

.section-overview .section-text h3 {
  font-size: 1.6em;
  color: #333;
}

.section-overview .section-text p {
  font-size: 1.1em;
  line-height: 23px;
  color: #333;
}

@media screen and (max-width: 968px) {
  .project-overview {
    padding: 2em;
  }

  .project-overview .header {
    margin: 1em 0;
    
  }

  .project-overview .header h2 {
    margin-bottom: 0.3em;
    font-size: 2em;
  }

  .project-overview .header .block {
    margin-top: 0.3em;
  }

  .project-overview .header h3 {
    font-size: 1.5em;
    margin-bottom: 0.3em;
    color: #fff;
  }

  .section-overview {
    flex-direction: column;
    margin: 1em 0;
  }

  .section-overview .project-image {
    width: 100%;
    height: 400px;
    margin: 1em;
  }
}


@media screen and (max-width: 568px) {
  .project-overview .header h2 {
    font-size: 1.8em;
  }

  .project-overview .header h3 {
    font-size: 1.3em;
  }

  .section-overview .section-text p {
    font-size: 1em;
  

  }
  
}
